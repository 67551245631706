import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Participant } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

import SelectGroup from '../../form/SelectGroup';
import FormGroup from '../../form/FormGroup';
import { usePermissions } from '../../../hooks/usePermissions';
import useCompanyNavigate from '../../../hooks/useCompanyNavigate';
import { getFieldLabel, getFieldOptions } from '../../../utils/utils';


type AddEditParticipantModalProps = {
  modalTitle: string;
  participant?: Participant;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  onModalClose: () => void;
  fieldConfigs: any[];
};

interface FormValues {
  city: string;
  discordId: string;
  email: string;
  emailScnd: string;
  firstname: string;
  hubspotId: string;
  lastname: string;
  phoneNumber: string;
  salutation: number
  secondName: string;
  address: string;
  zipCode: string;
  professionalTitle: string;
}

const AddEditParticipantModal: React.FC<AddEditParticipantModalProps> = ({
  modalTitle,
  participant,
  onSubmitSuccess,
  onModalClose,
  fieldConfigs,
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const { participantId } = useParams<{ participantId?: string }>();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const { userHasPermissionByRight } = usePermissions();
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const companyNavigate = useCompanyNavigate();

  const [formValues, setFormValues] = useState<FormValues>({
    city: participant?.city ?? '',
    discordId: participant?.discordId ?? '',
    email: participant?.email ?? '',
    emailScnd: participant?.emailScnd ?? '',
    firstname: participant?.firstname ?? '',
    hubspotId: participant?.hubspotId ?? '',
    lastname: participant?.lastname ?? '',
    phoneNumber: participant?.phoneNumber ?? '',
    salutation: participant?.salutation ?? 0,
    secondName: participant?.secondName ?? '',
    address: participant?.address ?? '',
    zipCode: participant?.zipCode ?? '',
    professionalTitle: participant?.professionalTitle ?? '',
  });

  const [initialFormValues] = useState<FormValues>(formValues);

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        const response = participantId
          ? await ApiClient.put(`/participants/${participantId}`, formValues)
          : await ApiClient.post('/participants', formValues);

        const participantsId = response.data.id;
        onSubmitSuccess(participantsId);
        setFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose();
        setValidated(false);
      }
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormValues((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button p-0"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}>
                  <FontAwesomeIcon width={30} icon={faXmark} /> Schließen
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Row>
              <Col lg={4}>
                <h6>Personendaten</h6>
              </Col>
              <Col>
                <SelectGroup<FormValues>
                  id="salutation"
                  label={getFieldLabel(fieldConfigs, 'participants.salutation')}
                  options={getFieldOptions(fieldConfigs, 'participants.salutation')}
                  value={formValues.salutation || ''}
                  onChange={handleInputChange}
                  required
                />
                <FormGroup<FormValues>
                  id="firstname"
                  label={getFieldLabel(fieldConfigs, 'participants.firstname')}
                  value={formValues.firstname}
                  onChange={handleInputChange}
                  required
                />
                <FormGroup<FormValues>
                  id="lastname"
                  label={getFieldLabel(fieldConfigs, 'participants.lastname')}
                  value={formValues.lastname}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col>
                <SelectGroup<FormValues>
                  id="professionalTitle"
                  label={getFieldLabel(fieldConfigs, 'participants.professionalTitle')}
                  options={getFieldOptions(fieldConfigs, 'participants.professionalTitle')}
                  value={formValues.professionalTitle || ''}
                  onChange={handleInputChange}
                />

                <FormGroup<FormValues>
                  id="secondName"
                  label={getFieldLabel(fieldConfigs, 'participants.secondName')}
                  value={formValues.secondName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

            <Row>
              <Col>
                <h6>Kontakt</h6>
              </Col>
              <Col>
                <FormGroup<FormValues>
                  id="address"
                  label={getFieldLabel(fieldConfigs, 'participants.address')}
                  value={formValues.address}
                  onChange={handleInputChange}
                />
                <FormGroup<FormValues>
                  id="city"
                  label={getFieldLabel(fieldConfigs, 'participants.city')}
                  value={formValues.city}
                  onChange={handleInputChange}
                />

                <FormGroup<FormValues>
                  id="zipCode"
                  label={getFieldLabel(fieldConfigs, 'participants.zipCode')}
                  value={formValues.zipCode}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <FormGroup<FormValues>
                  id="email"
                  label={getFieldLabel(fieldConfigs, 'participants.email')}
                  value={formValues.email}
                  onChange={handleInputChange}
                  required
                  type="email"
                />
                <FormGroup<FormValues>
                  id="emailScnd"
                  label={getFieldLabel(fieldConfigs, 'participants.emailScnd')}
                  value={formValues.emailScnd}
                  onChange={handleInputChange}
                />
                <FormGroup<FormValues>
                  id="phoneNumber"
                  label={getFieldLabel(fieldConfigs, 'participants.phoneNumber')}
                  value={formValues.phoneNumber}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>


            <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

            <Row>
              <Col>
                <h6>Verknüpfungen</h6>
              </Col>
              <Col>
                <FormGroup<FormValues>
                  id="discordId"
                  label={getFieldLabel(fieldConfigs, 'participants.discordId')}
                  value={formValues.discordId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <FormGroup<FormValues>
                  id="hubspotId"
                  label={getFieldLabel(fieldConfigs, 'participants.hubspotId')}
                  value={formValues.hubspotId}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditParticipantModal;
