import { Col, OverlayTrigger, Row, Tooltip, Card } from 'react-bootstrap';
import { Deal, FieldConfig, IGlobalMeasureDetails, Participant, ProgressBarValues } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { calculateProgressFromStartAndEndDate, formatDateWithoutTime, formatEuro, getDealExpirationDetails, getFieldConfigByResourceName, getFieldLabel, parseDate } from '../../../utils/utils';
import ProgressBarComponent from '../../ProgressBarComponent';
import ModuleList from '../../ModuleList';
import DealManagement from '../../DealManagement';
import { useEffect, useState } from 'react';
import BGSViewerModal from '../../BGSViewerModal';
import { ApiClient } from '../../../services/ApiClient';
import WvViewerModal from '../../WvViewerModal';
import { bgsStatusColorMap, bgsStatusIconMap, wvStatusColorMap, wvStatusIconMap } from '../../filter/iconAndColorMappings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { faCopy, faExclamationTriangle, faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import SendPsmCodeModal from '../modal/SendPsmCodeModal';
import { useToast } from '../../../services/context/ToastContext';
import StatusIndicator from '../../StatusIndicator';

interface DealInfosProps {
    isLoading?: boolean;
    deal: Deal;
    participant?: Participant
    label: string;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    fieldConfigs: FieldConfig[];
}

const DealInfos: React.FC<DealInfosProps> = ({ deal, participant, label, isLoading, onSubmitSuccess, fieldConfigs }) => {
    const { showToast } = useToast();
    const [showBgsViewerModal, setShowBgsViewerModal] = useState<boolean>(false);
    const [showWvViewerModal, setShowWvViewerModal] = useState<boolean>(false);
    const [showSendPsmCodeModal, setShowSendPsmCodeModal] = useState<boolean>(false);
    const [emailTemplateSettings, setEmailTemplateSettings] = useState<{ title: string, body: string }>();
    const [globalMeasureDetails, setGlobalMeasureDetails] = useState<IGlobalMeasureDetails>();

    const { dayCount, isActive, expirationDate } = getDealExpirationDetails(deal.start);

    useEffect(() => {
        const getTemplateDetails = async () => {
            const res = await ApiClient.get('/settings?ident=contractEmailTemplate')
            //setEmailTemplateSettings(res.data.list[0].details ?? {})
        }

        const getMeasureDetails = async () => {
            const res = await ApiClient.get('/settings?ident=globalMeasureDetails')
            //setGlobalMeasureDetails(res.data.list[0].details ?? {})
        }
        getTemplateDetails()
        getMeasureDetails()
    }, [onSubmitSuccess]);

    const progress = deal?.start && deal?.end && calculateProgressFromStartAndEndDate(formatDateWithoutTime(deal?.start), formatDateWithoutTime(deal.extendedEnd ?? deal.end), formatDateWithoutTime(deal?.abortDate ??
        "0000-00-00 00:00:00"));


    const dealAbortDate = deal?.abortDate ? formatDateWithoutTime(deal?.abortDate) : ''

    const abort = dealAbortDate && dealAbortDate !== '0000-00-00 00:00:00' && parseDate(dealAbortDate).getTime() < new Date().getTime() ? parseDate(deal.abortDate) : null;

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text || '')
        showToast('Erfolgreich kopiert')
    }

    const calculateIndividualProgressBarValues = (
        startDate: Date,
        endDate: Date,
        totalStartDate: Date,
        totalEndDate: Date,
        abort: boolean
    ): ProgressBarValues => {
        const totalDuration = totalEndDate.getTime() - totalStartDate.getTime();
        const segmentStartOffset =
            ((startDate.getTime() - totalStartDate.getTime()) / totalDuration) * 100;
        const segmentProgress =
            ((endDate.getTime() - startDate.getTime()) / totalDuration) * 100;

        return {
            progress: segmentProgress,
            startOffset: segmentStartOffset,
            now: progress || 0,
            label: `${formatDateWithoutTime(startDate.toLocaleDateString())} - ${formatDateWithoutTime(endDate.toLocaleDateString())}`,
            abort
        };
    };


    const totalStartDate = new Date(deal.start);
    const totalEndDate = new Date(deal.extendedEnd ?? deal.end );

    // Calculate values for "Zeitraum Vollzeit"
    const vollzeitValues = calculateIndividualProgressBarValues(
        new Date('2023-10-02'),
        new Date('2024-06-13'),
        totalStartDate,
        totalEndDate,
        !!abort
    );

    // Calculate values for "Zeitraum Berufsbegleitend"
    const berufsbegleitendValues = calculateIndividualProgressBarValues(
        new Date('2024-06-13'),
        new Date('2025-9-23'),
        totalStartDate,
        totalEndDate,
        !!abort
    );

    // Calculate values for "Zeitraum verlängert"
    const verlaengertValues = deal.extendedEnd ? calculateIndividualProgressBarValues(
        new Date(deal.end),
        new Date(deal.extendedEnd),
        totalStartDate,
        new Date(deal.extendedEnd),
        !!abort
    ) : null

    return (
        <>
            <Row>
                <Col md={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Deal Übersicht</Card.Title>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.offerTitle')}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.offerTitle}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.degreeDesignation')}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.degreeDesignation}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.start')}</span></Col>
                                <Col xs={8}><span className="text-black">{formatDateWithoutTime(deal.start)}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.offerNumber')}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.offerNumber}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.offerDate')}</span></Col>
                                <Col xs={8}><span className="text-black">{formatDateWithoutTime(deal.offerDate)}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.actionNumber')}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.actionNumber}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.bgsnumber')}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.bgsnumber}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.macbook')}</span></Col>
                                <Col xs={8}><span className="text-black">{getEnumValue(
                                    getFieldConfigByResourceName(fieldConfigs, 'lastDeal.macbook')?.options ?? {},
                                    deal.macbook?.toString()
                                )}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>Förderungsart</span></Col>
                                <Col xs={8}><span className="text-muted">{deal.besidesTheJob ? 'Berufsbegleitend' : "Afa gefördert"}</span></Col>
                            </Row>
                            {/* Arbeitgeber Details */}
                            {/* <Row className="mb-3">
                                <Col xs={4}><span>Arbeitgeber</span></Col>
                                <Col xs={8}>
                                    <Row className="cursor-pointer">
                                        <Col xs={10}>
                                            <span className='text-black'>
                                                OneCareer GmbH (TBD)
                                            </span>
                                        </Col>
                                        <Col xs={2} className="d-flex justify-content-end align-items-center" >
                                            <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Dokumente</Card.Title>
                            <Row className="mb-3 cursor-pointer" onClick={() => setShowBgsViewerModal(true)} >
                                <Col xs={10}>
                                    <span className="text-black">Bildungsgutschein</span> <br />
                                    <span className={`${bgsStatusColorMap[deal.bgsStatus]}`}>
                                        <FontAwesomeIcon icon={bgsStatusIconMap[deal.bgsStatus]} className="me-1" />
                                        {getEnumValue(
                                            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus')?.options ?? {},
                                            deal.bgsStatus?.toString()
                                        )} <span className="text-muted">· {deal.bgsnumber}</span>
                                    </span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end align-items-center">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                                </Col>
                            </Row>

                            <Row className="mb-3 cursor-pointer" onClick={() => setShowWvViewerModal(true)}>
                                <Col xs={10}>
                                    <span className="text-black">Weiterbildungsvertrag</span> <br />
                                    <span className={`${wvStatusColorMap[deal.wvStatus]}`}>
                                        <FontAwesomeIcon icon={wvStatusIconMap[deal.wvStatus]} className="me-1" />
                                        {getEnumValue(
                                            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.wvStatus')?.options ?? {},
                                            deal.wvStatus?.toString()
                                        )} <span className="text-muted">· {deal.bgsnumber}</span>
                                    </span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end align-items-center" >
                                    <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Zeitraum</Card.Title>
                            <Row className="mb-4">
                                <Col xs={4}><span>Gesamtzeitraum</span></Col>
                                <Col xs={8}>
                                    <div className="d-flex flex-column">
                                        <div className='w-100'>
                                            <div>
                                                <ProgressBarComponent abortDate={dealAbortDate} label={`${formatDateWithoutTime(deal.start)}-${formatDateWithoutTime(deal.extendedEnd ?? deal.end)}`} isCompleteTimeframe startOffset={0} progress={progress || 0} now={progress || 0} abort={!!abort} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {/* Unterzeiträume */}

                            {/* <Row className="mb-4">
                                <Col xs={4}><span>Zeitraum Vollzeit</span></Col>
                                <Col xs={8}>                  <div className="d-flex flex-column mb-2">
                                    <div className='mb-2 w-100'>
                                        <div>
                                            <ProgressBarComponent
                                                progress={vollzeitValues.progress}
                                                startOffset={vollzeitValues.startOffset}
                                                now={vollzeitValues.now}
                                                label={vollzeitValues.label}
                                                abort={vollzeitValues.abort}
                                                color="black"
                                            />
                                        </div>
                                    </div>
                                </div>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col xs={4}><span>Zeitraum berufsbegl.</span></Col>
                                <Col xs={8}>                  <div className="d-flex flex-column mb-2">
                                    <div className='mb-2 w-100'>
                                        <div>
                                            <ProgressBarComponent
                                                progress={berufsbegleitendValues.progress}
                                                startOffset={berufsbegleitendValues.startOffset}
                                                now={berufsbegleitendValues.now}
                                                label={berufsbegleitendValues.label}
                                                abort={berufsbegleitendValues.abort}
                                                color="black"
                                            />
                                        </div>
                                    </div>
                                </div>
                                </Col>
                            </Row> */}

                            {verlaengertValues &&
                                <Row className="mb-4">
                                    <Col xs={4}><span>Zeitraum verlängert</span></Col>
                                    <Col xs={8}><div className="d-flex flex-column mb-2">
                                        <div className='mb-2 w-100'>
                                            <div>
                                                <ProgressBarComponent
                                                    progress={verlaengertValues!.progress}
                                                    startOffset={verlaengertValues!.startOffset}
                                                    now={verlaengertValues!.now}
                                                    label={verlaengertValues!.label}
                                                    abort={verlaengertValues!.abort}
                                                    color="black"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Unterrichtseinheiten</Card.Title>

                            <Row className="mb-3">
                                <Col xs={4}><span>Vollzeit</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        {deal.ue} Unterrichtseiheiten <br />
                                        {deal.uePerDay} pro Tag (Vollzeit) <br />
                                        {deal.durationWeeks} Wochen; {deal.durationMonths} Monate
                                    </span>
                                </Col>
                            </Row>


                            {/* <Row className="mb-3">
                                <Col xs={4}><span>Berufsbegleitend</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        {deal.ue} Unterrichtseiheiten (TBD) <br />
                                        {deal.uePerDay} pro Tag (Teilzeit)  (TBD) <br />
                                        {deal.durationWeeks} Wochen; {deal.durationMonths} Monate  (TBD)
                                    </span>
                                </Col>
                            </Row> */}

                            <Row className="mb-3">
                                <Col xs={4}>
                                    <span>Rücktrittsrecht</span>
                                </Col>
                                <Col xs={8}>
                                    {isActive ? (
                                        <span className="text-danger"> <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" /> Noch {dayCount} Tage aktiv (bis {formatDateWithoutTime(expirationDate.toString())})</span>
                                    ) : (
                                        <span className="text-black">
                                            Abgelaufen am {formatDateWithoutTime(expirationDate.toString())}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Finanzen</Card.Title>

                            <Row className="mb-3">
                                <Col xs={4}><span>Preis</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        {formatEuro(deal.priceTotalGross)} (Brutto) <br /> {formatEuro(deal.priceTotalNet)} (Netto)
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4}><span>Preis, Steuern</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        {formatEuro(deal.priceTax)}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4}><span>Weiteres</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        Selbstzahler: {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'lastDeal.selfPay')?.options ?? {}, deal?.selfPay)} <br />
                                        PSM wird vom Bildungsträger bezahlt: {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'lastDeal.psmIncludet')?.options ?? {}, deal?.psmIncludet)}
                                    </span>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-2">Module</Card.Title>
                            {deal?.listOfReleases ? <ModuleList modules={deal?.listOfReleases as any} /> : <p>-</p>}
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Prüfungen</Card.Title>
                            {/* <Row className="mb-3 cursor-pointer">
                                <Col xs={10}>
                                    <span className="text-black">PSM I Prüfung</span> <br />
                                    <span>Code: TBD</span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end align-items-center" onClick={() => { }}>
                                    <FontAwesomeIcon icon={faCopy} className="me-3 text-primary" onClick={() => handleCopyClick('somecode1')} />
                                    <FontAwesomeIcon icon={faPaperPlaneTop} className="me-1 text-primary" onClick={() => setShowSendPsmCodeModal(true)} />
                                </Col>
                            </Row>

                            <Row className="mb-3 cursor-pointer">
                                <Col xs={10}>
                                    <span className="text-black">PSM II Prüfung</span> <br />
                                    <span>Code: TBD</span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end align-items-center">
                                    <FontAwesomeIcon icon={faCopy} className="me-3 text-primary" onClick={() => handleCopyClick('somecode2')} />
                                    <FontAwesomeIcon icon={faPaperPlaneTop} className="me-1 text-primary" onClick={() => setShowSendPsmCodeModal(true)} />
                                </Col>
                            </Row> */}
                            <Row className="mb-3 cursor-pointer">
                                <Col>
                                    <span className="text-black">Externe Prüfung</span> <br />

                                    {deal.externalTest ? (
                                        <>
                                            <span>
                                                <StatusIndicator
                                                    isActive={deal.externalReg}
                                                    activeText="Teilnehmer registriert"
                                                    inactiveText="Teilnehmer nicht registriert"
                                                />
                                            </span>
                                            <br />
                                            {deal.externalWhen && deal.externalWhere && (
                                                <span>{`Am ${formatDateWithoutTime(deal.externalWhen)} in ${deal.externalWhere}`}</span>
                                            )}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                {(deal.status === 0 || deal.status === 1 || deal.status === 3) && <DealManagement
                    fieldConfigs={fieldConfigs}
                    onSubmitSuccess={onSubmitSuccess}
                    deal={deal}
                />}

            </Row>

            <BGSViewerModal
                onSubmitSuccess={() => { }}
                show={showBgsViewerModal}
                handleClose={() => setShowBgsViewerModal(false)}
                media={[]}
                title="Bildungsgutschein"
                fieldConfigs={fieldConfigs}
                deal={deal}
            />

            <WvViewerModal
                show={showWvViewerModal}
                handleClose={() => setShowWvViewerModal(false)}
                media={[]}
                onSubmitSuccess={() => { }}
                title="Weiterbildungsvertrag"
                fieldConfigs={fieldConfigs}
                deal={deal}
            />

            {showSendPsmCodeModal && <SendPsmCodeModal onSubmitSuccess={onSubmitSuccess} onModalClose={() => setShowSendPsmCodeModal(false)}></SendPsmCodeModal>}

            {/* {
                deal && emailTemplateSettings && globalMeasureDetails && <GenerateWvModal
                    modalTitle='WV erstellen'
                    buttonName='Erstelle WV'
                    deal={deal}
                    participant={participant}
                    onSubmitSuccess={onSubmitSuccess}
                    emailTemplateSettings={emailTemplateSettings}
                    globalMeasureDetails={globalMeasureDetails}
                ></GenerateWvModal>
            } */}
        </>);
};

export default DealInfos;