import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ProgressBarComponentProps {
    progress: number;
    startOffset: number;
    now: number;
    label?: string;
    color?: string;
    abort?: boolean;
    isCompleteTimeframe?: boolean;
    abortDate?: string;
}

/**
 * ProgressBarComponent
 *
 * A reusable React component to render a progress bar with optional labels, start and end indicators,
 * and a vertical "now" line to represent the current position within the timeframe.
 *
 * Props:
 * - `progress` (number): The width of the progress bar as a percentage.
 * - `startOffset` (number): The start position of the progress bar as a percentage from the left.
 * - `now` (number): The position of the "Now" line as a percentage from the left.
 * - `label` (string, optional): The label displayed for the progress bar, typically a date range.
 * - `color` (string, optional): The color of the progress bar.
 * - `abort` (boolean, optional): If true, changes the "Now" line color to red and highlights the abort date.
 * - `isCompleteTimeframe` (boolean, optional): If true, renders the progress bar as the complete timeframe with specific styles.
 * - `abortDate` (string, optional): The date displayed when the progress is marked as aborted.
 */

const ProgressBarComponent: React.FC<ProgressBarComponentProps> = ({
    progress,
    startOffset,
    now,
    label,
    color,
    abort,
    isCompleteTimeframe,
    abortDate,
}) => {
    return (
        <>
            {isCompleteTimeframe ? (
                <div>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${now}`}>{now}%</Tooltip>}
                    >
                        <div className="py-2">
                            <div className="progress-bar-container">
                                <div
                                    className="progress-bar-overlay"
                                    style={{ width: `${100 - now}%` }}
                                />
                                <div
                                    className={`progress-bar-vertical-line ${abort ? 'abort-line' : ''}`}
                                    style={{ left: `${now}%` }}
                                />
                            </div>
                        </div>
                    </OverlayTrigger>

                    {label && (
                        <div className="d-flex justify-content-between">
                            <span className="text-black">{label.split('-')[0]}</span>
                            {abort ? (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-abort">
                                            Abbruchdatum: {abortDate}
                                        </Tooltip>
                                    }
                                >
         
                                        <span className="text-danger">{label.split('-')[1]}</span>
    
                                </OverlayTrigger>
                            ) : (
                                <span className="text-black">{label.split('-')[1]}</span>
                            )}
                        </div>
                    )}
                </div>

            ) : (
                <div className="py-2">
                    <div className="progress-bar-container" style={{ background: '#EBEEFD' }}>
                        <div className="progress-bar-inner-container">
                            {/* Progress Bar */}
                            <div
                                className="progress-bar"
                                style={{
                                    left: `${startOffset}%`,
                                    width: `${progress}%`,
                                    backgroundColor: color || 'rgba(26,160,83,1)',
                                }}
                            />

                            {/* Start Vertical Line */}
                            <div
                                className="progress-bar-start-end-line"
                                style={{
                                    left: `${startOffset}%`,
                                }}
                            />

                            {/* End Vertical Line */}
                            <div
                                className="progress-bar-start-end-line"
                                style={{
                                    left: `${startOffset + progress}%`,
                                }}
                            />
                        </div>

                        {/* "Now" Line */}
                        <div
                            className={`progress-bar-vertical-line ${abort ? 'abort-line' : ''}`}
                            style={{ left: `${now}%` }}
                        />

                        {/* Label */}
                        {label && !isCompleteTimeframe && (
                            <div className="mt-2">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-${label}`}>{label}</Tooltip>}
                                >
                                    <span className="text-black">{label}</span>
                                </OverlayTrigger>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProgressBarComponent;
