import { useSearchParams } from 'react-router-dom';

/**
 * A custom hook to manage and persist tab state using query parameters in the URL.
 * 
 * @param {string} defaultTab - The default tab to use if no tab is specified in the query parameters.
 * @returns {[string, (tab: string) => void]} - Returns the currently selected tab and a function to set the tab.
 *
 * Usage:
 * 
 * ```tsx
 * const [selectedTab, setTab] = useTabState('defaultTab');
 * 
 * // To set a new tab
 * setTab('newTab');
 * 
 * // The selected tab is automatically synced with the URL query parameter `tab`.
 * ```
 * 
 * This hook is ideal for scenarios where tab navigation needs to persist through URL sharing or page refreshes.
 */
export function useTabState(defaultTab: string) {
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedTab = searchParams.get('tab') || defaultTab;

    const setTab = (tab: string) => {
        setSearchParams({ tab });
    };

    return [selectedTab, setTab] as const;
}