import React, { useState } from 'react';
import { Card, Row, Col, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Deal, FieldConfig } from '../../../interfaces';
import { bgsStatusColorMap, bgsStatusIconMap, dealStatusColorMap, dealStatusIconMap, wvStatusColorMap, wvStatusIconMap } from '../../filter/iconAndColorMappings';
import { getEnumValue } from '../../../utils/enum';
import { getFieldConfigByResourceName, getFieldLabel, formatEuro } from '../../../utils/utils';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import BGSViewerModal from '../../BGSViewerModal';
import WvViewerModal from '../../WvViewerModal';

interface CurrentDealInfosProps {
    deal?: Deal;
    fieldConfigs: FieldConfig[];
}

/**
 * CurrentDealInfos Component
 *
 * This component displays detailed information about a current deal, including:
 * - Deal overview with title, status, equipment (e.g., macbook), and price details.
 * - Document-related information, including the Bildungsgutschein and Weiterbildungsvertrag.
 * 
 * The component uses multiple helper functions and mappings for labels, status icons, and colors.
 * 
 * Props:
 * - deal (Deal): An optional deal object containing various properties such as title, status, price, etc.
 * - fieldConfigs (FieldConfig[]): Array of field configuration objects used to dynamically fetch labels and options.
 */
const CurrentDealInfos: React.FC<CurrentDealInfosProps> = ({
    deal,
    fieldConfigs,
}) => {
    const [showBgsViewerModal, setShowBgsViewerModal] = useState<boolean>(false);
    const [showWvViewerModal, setShowWvViewerModal] = useState<boolean>(false);
    if (!deal) return null;

    return (
        <Row className="g-3">
            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        <Card.Title className="mb-4">Deal Übersicht</Card.Title>
                        <Row className="mb-3">
                            <Col xs={4}><span>Titel:</span></Col>
                            <Col xs={8}><span className="text-black">{deal.offerTitle}</span></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>Status:</span></Col>
                            <Col xs={8}>
                                <span className={`${dealStatusColorMap[deal.status]}`}>
                                    <FontAwesomeIcon icon={dealStatusIconMap[deal.status]} className="me-1" />
                                    {getEnumValue(
                                        getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {},
                                        deal.status?.toString()
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.macbook')}</span></Col>
                            <Col xs={8}><span className="text-black">{getEnumValue(
                                getFieldConfigByResourceName(fieldConfigs, 'lastDeal.macbook')?.options ?? {},
                                deal.macbook?.toString()
                            )}</span></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>Preis:</span></Col>
                            <Col xs={8}>
                                <span className="text-black">
                                    {formatEuro(deal.priceTotalGross)} (Brutto) <br /> {formatEuro(deal.priceTotalNet)} (Netto)
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>Förderungsart:</span></Col>
                            <Col xs={8}><span className="text-muted">{deal.besidesTheJob ? 'Berufsbegleitend' : "Afa gefördert"}</span></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        <Card.Title className="mb-4">Dokumente</Card.Title>
                        <Row className="mb-3 cursor-pointer" onClick={() => setShowBgsViewerModal(true)}>
                            <Col xs={10}>
                                <span className="text-black">Bildungsgutschein</span> <br />
                                <span className={`${bgsStatusColorMap[deal.bgsStatus]}`}>
                                    <FontAwesomeIcon icon={bgsStatusIconMap[deal.bgsStatus]} className="me-1" />
                                    {getEnumValue(
                                        getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus')?.options ?? {},
                                        deal.bgsStatus?.toString()
                                    )} <span className="text-muted">· {deal.bgsnumber}</span>
                                </span>
                            </Col>
                            <Col xs={2} className="d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                            </Col>
                        </Row>

                        <Row className="mb-3 cursor-pointer" onClick={() => setShowWvViewerModal(true)}>
                            <Col xs={10}>
                                <span className="text-black">Weiterbildungsvertrag</span> <br />
                                <span className={`${wvStatusColorMap[deal.wvStatus]}`}>
                                    <FontAwesomeIcon icon={wvStatusIconMap[deal.wvStatus]} className="me-1" />
                                    {getEnumValue(
                                        getFieldConfigByResourceName(fieldConfigs, 'lastDeal.wvStatus')?.options ?? {},
                                        deal.wvStatus?.toString()
                                    )} <span className="text-muted">· {deal.bgsnumber}</span>
                                </span>
                            </Col>
                            <Col xs={2} className="d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>

            <BGSViewerModal
                onSubmitSuccess={() => { }}
                show={showBgsViewerModal}
                handleClose={() => setShowBgsViewerModal(false)}
                media={[]}
                title="Bildungsgutschein"
                fieldConfigs={fieldConfigs}
                deal={deal}
            />

            <WvViewerModal
                show={showWvViewerModal}
                handleClose={() => setShowWvViewerModal(false)}
                media={[]}
                onSubmitSuccess={() => { }}
                title="Weiterbildungsvertrag"
                fieldConfigs={fieldConfigs}
                deal={deal}
            />
        </Row>
    );
};

export default CurrentDealInfos;
