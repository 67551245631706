import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

interface PaginationInfoProps {
  currentPage: number;
  limit: number;
  totalEntries: number;
  onLimitChange: (newLimit: number) => void;
  pageSizes?: number[];
}

/**
 * PaginationInfo Component
 * 
 * Displays pagination details such as the current range of entries being viewed,
 * the total number of entries, and a dropdown to select the number of entries per page.
 * 
 * Props:
 * - currentPage: The current page number being displayed.
 * - limit: The number of entries displayed per page.
 * - totalEntries: The total number of entries available.
 * - onLimitChange: Callback function to handle changes to the page size.
 * - pageSizes: Optional array of page size options for the dropdown (default: [25, 50, 100, 250, 500]).
 */

const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  limit,
  totalEntries,
  onLimitChange,
  pageSizes = [25, 50, 100, 250, 500], // Default to existing page sizes
}) => {
  const startIndex = totalEntries > 0 ? (currentPage - 1) * limit + 1 : 0;
  const endIndex = totalEntries > 0 ? Math.min(startIndex + limit - 1, totalEntries) : 0;

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      onLimitChange(parseInt(eventKey));
    }
  };

  return (
    <div className="dataTables_info d-flex align-items-center">
      <span>{startIndex}-{endIndex} von {totalEntries} Einträgen</span>
      <span className="mx-2"> | Ergebnisse pro Seite:</span>
      <DropdownButton
        id="dropdown-page-size-button"
        title={`${limit}`}
        onSelect={handleSelect}
        className="mx-1"
        size="sm"
      >
        {pageSizes.map((size) => (
          <Dropdown.Item key={size} eventKey={size.toString()}>
            {size}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default PaginationInfo;
