import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface SmileyPickerProps {
    id: string;
    label: string;
    value: number;
    onChange: (value: number) => void;
    smileyMap: Record<number, IconDefinition>;
    smileyColorMap: Record<number, string>;
}

/**
 * SmileyPicker Component
 * 
 * A reusable React component for selecting a smiley face with corresponding color.
 * Each smiley is represented by an icon and is color-coded. The selected smiley is 
 * visually highlighted. This component integrates seamlessly with form state management.
 * 
 * Props:
 * - `id`: Unique identifier for the form control.
 * - `label`: Label displayed above the smiley picker.
 * - `value`: The currently selected smiley (key from `smileyMap`).
 * - `onChange`: Callback function triggered when a smiley is selected. Receives the smiley key as an argument.
 * - `smileyMap`: A mapping of smiley keys to FontAwesome icons.
 * - `smileyColorMap`: A mapping of smiley keys to their corresponding colors.
 */
const SmileyPicker: React.FC<SmileyPickerProps> = ({
    id,
    label,
    value,
    onChange,
    smileyMap,
    smileyColorMap,
}) => {
    return (
        <Form.Group controlId={id} className="mb-3">
            {label && <Form.Label>{label}</Form.Label>}
            <div className="d-flex justify-content-center gap-3">
                {Object.entries(smileyMap).map(([key, icon]) => {
                    const smileyKey = parseInt(key, 10);
                    const isSelected = smileyKey === value;

                    return (
                        <div
                            key={key}
                            className={`smiley-picker-item ${isSelected ? 'selected' : ''}`}
                            style={{
                                '--selected-bg-color': smileyColorMap[smileyKey],
                            } as React.CSSProperties}
                            onClick={() => onChange(smileyKey)}
                        >
                            <FontAwesomeIcon icon={icon} size="2x" />
                        </div>
                    );
                })}
            </div>
        </Form.Group>
    );
};

export default SmileyPicker;
